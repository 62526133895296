<template>
  <div class="transfer_voucher_page">
    <myTable title="订单列表" :list="list" :columns="columns">
      <template slot="btns" slot-scope="item">
        <div class="table_btns">
          <div class="table_btn" @click="add(item.data.row)" v-show="item.data.row.intefileType">详情</div>
          <div class="table_btn" @click="add(item.data.row)" v-show="!item.data.row.intefileType">上传转账凭证</div>
        </div>
      </template>
    </myTable>
    <el-dialog
      title="订单列表"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="show = false"
      :visible.sync="show"
    >
      <el-form
        ref="comForm"
        :model="form"
        style="padding: 0 0.5rem"
        label-width="1.5rem"
      >
        <template v-for="item in fromList">
          <el-form-item
            :key="item.prop"
            :label="item.label"
            :prop="item.prop || ''"
            :required="item.required || false"
            :rules="item.rules || null"
            :size="item.size || ''"
          >
            <el-input
              v-if="item.type === 'text'"
              :disabled="item.disabled"
              v-model="form[item.prop]"
            ></el-input>
            <el-input
              v-else-if="item.type === 'number'"
              :disabled="item.disabled"
              v-model="form[item.prop]"
              onkeyup="this.value = this.value.replace(/[^\d.]/g,'')"
            ></el-input>
            <el-upload
              :headers="{ token: $store.state.userInfo.token }"
              v-else-if="item.type === 'upload'"
              :on-success="onSuccess"
              class="avatar-uploader"
              :action="constant.UPLOAD_URL + '/file/upload'"
              :file-list="fileList"
              :show-file-list="true"
              list-type="picture-card"
              :on-preview="handlePictureCardPreview"
              :on-remove="handleRemove"
              :limit="limitNumber"
              :on-exceed="exceedNumber"
            >
              <i class="el-icon-plus"></i>
            </el-upload>
          </el-form-item>
        </template>
      </el-form>
      <div style="text-align: center">
        <el-button type="primary" @click="onSubmit">提交</el-button>
        <el-button @click="show = false">取消</el-button>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="dialogVisible">
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
    <comPagination :params="params" @changePage="changePage"></comPagination>
  </div>
</template>

<script>
import myTable from "@/components/admin/table.vue";
import comPagination from "@/components/admin/pagination.vue";
export default {
  name: "transferVoucher",
  components: {
    comPagination,
    myTable,
  },
  data() {
    return {
      limitNumber:1,  //上传图片数量
      columns: [
        {
          prop: "orderid",
          label: "订单号",
        },
        {
          prop: "price",
          label: "订单金额",
        },
        {
          prop: "ordertype",
          label: "订单类型",
        },
      ],
      form: {},
      fromList: [
        {
          prop: "ordertype",
          type: "text",
          label: "类型",
          disabled: true,
        },
        {
          prop: "orderid",
          type: "text",
          label: "订单号",
          disabled: true,
        },
        {
          prop: "number",
          type: "text",
          label: "学员人数",
          disabled: true,
        },
        {
          prop: "price",
          type: "text",
          label: "订单金额",
          disabled: true,
        },
        {
          prop: "semesterIds",
          type: "text",
          label: "学期",
          disabled: true,
        },
        {
          prop: "photo",
          type: "upload",
          label: "支付凭证",
        },
      ],
      list: [],
      //分页
      params: {
        page: 0,
        size: 10,
        total: 0,
      },

      //上传
      show: false, // 弹窗显示
      dialogImageUrl: "",
      showPicLsit: [], // 接口返回图片列表
      fileList: [],
      tabOrderId: "", // 选中的订单id
      dialogVisible: false,
    };
  },
  created() {},
  mounted() {
    this.init();
  },
  methods: {
    //初始化
    init() {
      this.getInfo();
    },
    getInfo() {
      let data = {
        userId: this.$store.state.userInfo.userId,
      };
      this.api.admin.findCreateOrder(this.params, data).then((res) => {
        console.log(res);
        this.list = res.data.list.map((item) => {
          item.semesterIds = item.semesterIds + "学期";
          item.ordertype =
            item.ordertype === "activity" ? "促销订单" : "正常订单";
          item.intefileType = item.intefile ? true : false;
          return item;
        });
        this.params.total = res.data.total;
      });
    },
    add(val) {
      let data = {
        id: val.id,
        orderType: val.ordertype === "正常订单" ? "normal" : "activity",
      };
      this.api.admin.findCostOrderById(data).then((res) => {
        this.form = {
          ordertype: res.data.ordertype === "normal" ? "正常订单" : "促销订单",
          orderid: res.data.orderid,
          number: res.data.number,
          price: res.data.price,
          semesterIds: res.data.semesterIds + "学期",
          photo: this.constant.URL_admin + res.data.intefile,
        };
        this.tabOrderId = res.data.id;
        this.show = true;
        let arr = res.data.intefile && res.data.intefile.split(",");
        this.fileList = [];
        arr.forEach((item) => {
          this.fileList.push({
            url: this.constant.URL_admin + item,
          });
        });
        
      });
    },
    onSuccess(res, file, list) {
      console.log(res, file, list);
      this.fileList.push(res.data);
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
      this.fileList.forEach((item, index) => {
        if (item.url === file.url) {
          this.fileList.splice(index, 1);
        }
      });
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    // 订单详情修改
    onSubmit() {
      let arr = [];
      this.fileList.forEach((res) => {
        arr.push(res.url.split(".com")[1]);
      });
      let data = {
        id: this.tabOrderId,
        intefile: arr.join(","),
      };
      this.api.admin.updateCostOrderInfo(data).then((res) => {
        console.log(res);
        this.show = false;
        this.form = {
          ordertype: "",
          orderid: "",
          number: "",
          price: "",
          semesterIds: "",
        };
        this.fileList = [];
      });
      this.getInfo()
   
   },
    //分页 todo 待测试
    changePage(val) {
      this.params.page = val;
      this.getInfo();
    },
    exceedNumber(){
      this.$notice({
        message: '最多只能上传一张图片',
      }).isShow();
    }
  },
};
</script>

<style lang="scss">
.transfer_voucher_page {
  .code_block {
    @include flex(row, flex-end, center);

    .el-input__inner {
      height: 60px;
      width: 250px;
      font-size: 20px;
    }
  }
}
</style>